export default {
  HERO: {
    title: 'SolClout Starter',
    description:
      'SolClout Starter is a messenger-based IDO platform. Social networking based IDO platform, SolClout Starter allows for projects to establish official communities. Users with best activity could receive an allocation for the initial decentralized offering.',
  },
  STARTER: [
    {
      title: 'Group Chat as hub for Project Marketing',
      description:
        'Built-in social activities within SolClout’s chat groups allow projects to bootstrap their social growth quickly using a plug-and-play model.',
      image: 'chat',
    },
    {
      title: 'Social Activity based IDO Allocation',
      description: [
        "Based on users' social activities regarding the project, users will be able to receive allocation for a project's fundraiser. Projects can now access investors who are also true supporters.",
        'SolClout rewards social activity through IDO allocations with exclusive projects they otherwise would not have access to. The key for us is to qualify this social activity by rewarding better track records with greater rewards, putting a premium on helpful knowledge and useful participation.',
      ],
      image: 'social',
    },
  ],
}
