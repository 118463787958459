import * as React from 'react'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import Hero from '~components/Hero'

import { StarterBlock } from './components/StarterBlock'

import DATA from './data'

export const Starter = () => (
  <Layout>
    <SEO title={DATA.HERO.title} />
    <Hero {...DATA.HERO} modifier="starter" />
    {DATA.STARTER.map((item, index) => (
      <StarterBlock key={item.title} {...item} isFirst={index === 0} />
    ))}
  </Layout>
)
